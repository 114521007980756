import { Box, colors, ContentText, fontWeights } from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';
import { GTMA } from '@components/GTMComponents';
import GooglePlayIcon from '@assets/images/googlePlay.svg';
import AppStoreIcon from '@assets/images/appleStore.svg';
import PhoneBackgroundIcon from '@assets/images/phone-background-icon.png';
import { TickItem } from '@components/IconItems';
import { Container } from './styled';

const AppSection = () => {
  const { t } = useTranslation();
  const contentfulCategory = 'thankYou';

  const thankYouItems = t<string, string[]>(`${contentfulCategory}.pointItem`, {
    returnObjects: true,
  });

  return (
    <Container
      color={colors.emperorGrey}
      size="xs"
      data-testid="app-section"
      padding="1.5rem 1rem"
    >
      <ContentText
        textAlign="center"
        color={colors.lavenderPurple300}
        fontWeight={400}
        fontSize="2rem"
        marginBottom="0.5rem"
      >
        <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
      </ContentText>
      <Box
        display="flex"
        alignContent="center"
        flexDirection="column"
        paddingLeft="2rem"
      >
        {Array.isArray(thankYouItems)
          ? thankYouItems.map((item, index) => (
              <TickItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                contentProps={{
                  fontWeight: fontWeights.light,
                  color: colors.snowWhite,
                  textAlign: 'left',
                  lineHeight: 2,
                }}
                iconProps={{
                  color: colors.snowWhite,
                  marginRight: '1rem',
                  size: '26px',
                }}
              >
                {item}
              </TickItem>
            ))
          : null}
      </Box>
      <ContentText
        textAlign="center"
        color={colors.snowWhite}
        fontWeight={300}
        fontSize="1rem"
      >
        <FormattedEnhancedMessage
          i18nKey={`${contentfulCategory}.contentText`}
        />
      </ContentText>

      <img
        src={PhoneBackgroundIcon}
        alt="phone icon"
        style={{ maxWidth: '350px', width: '100%' }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
      >
        <GTMA
          href={t(`${contentfulCategory}.appStoreLink.link`)}
          contentfulKey={`${contentfulCategory}.appStoreLink`}
        >
          <img src={AppStoreIcon} alt="appStore" />
        </GTMA>
        <GTMA
          href={t(`${contentfulCategory}.googlePlayLink.link`)}
          contentfulKey={`${contentfulCategory}.googlePlayLink`}
        >
          <img src={GooglePlayIcon} alt="googlePlay" />
        </GTMA>
      </Box>
    </Container>
  );
};

export default AppSection;
