import { RenderedRoute } from '@constants/routes';
import { Box, ContentText, IconArrowLeft } from '@fortum/elemental-ui';
import useRoutes from '@hooks/useRouting';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Step } from './styled';

type Props = {
  steps: RenderedRoute[];
};

const Stepper: React.FC<Props> = ({ steps }) => {
  const location = useLocation();
  const currentStep = steps.find((step) =>
    location.pathname.includes(step.path as string)
  );
  const { t } = useTranslation();
  const { redirectToPrevious } = useRoutes();

  const name = currentStep ? t(currentStep.name as string) : '';

  const stepsAmount = steps[steps.length - 1].order as number;

  const disableThankYouBackButton =
    currentStep?.path === '/thank-you' &&
    t('settings.disableThankYouBack') === 'true';

  function onBackButtonClick() {
    redirectToPrevious();
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" marginBottom="1rem" justifyContent="space-between">
        {disableThankYouBackButton ? (
          <div />
        ) : (
          <IconArrowLeft onClick={onBackButtonClick} />
        )}
        <ContentText display="flex" alignItems="center">
          {name}
        </ContentText>
        <div />
      </Box>
      <Box display="flex" gap="0.5rem">
        {currentStep &&
          Array.from({ length: stepsAmount }).map((_, index) => (
            <Step
              key={steps[index].name}
              done={index < (currentStep?.order as number)}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Stepper;
