import {
  Box,
  colors,
  ContentText,
  Link,
  LazyIcon,
  fontWeights,
  LazyIconProps,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';

interface ItemProps {
  icon: LazyIconProps['iconId'];
  title: string;
  price?: string | null;
  buttonText?: string;
  description?: string;
  activated?: boolean;
  onButtonClick?: () => void;
  onReadMoreClick?: () => void;
  onSwitchChange?: () => void;
  buttonDisabled?: boolean;
}

const ReadMoreLink = ({ onClick }: { onClick?: () => void }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link size="xs" color={colors.emperorGrey} onClick={onClick}>
    <FormattedEnhancedMessage i18nKey="common.readMore" />
  </Link>
);

const BankActivationBox: React.FC<ItemProps> = ({
  icon,
  title,
  description,
  onReadMoreClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      padding="1rem"
      border={`1px solid ${colors.fogGrey}`}
      borderRadius="0.5rem"
      margin="1rem 0"
    >
      <Box display="flex" alignItems="center">
        <LazyIcon iconId={icon} color={colors.ivyGreen600} size="3rem" />
        <Box marginLeft="1rem">
          <ContentText size="xl" color={colors.inkGrey}>
            {title}
          </ContentText>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="1rem"
        flexDirection="column"
      >
        <ContentText
          color={colors.inkGrey}
          fontSize="1rem"
          fontWeight={fontWeights.light}
        >
          {description}
        </ContentText>
        <Box textAlign="left" width="100%">
          <ReadMoreLink onClick={onReadMoreClick} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginTop="1rem"
        >
          <ContentText color={colors.lavenderPurple600} size="l">
            {t('common.freeOfCharge')}
          </ContentText>
        </Box>
      </Box>
    </Box>
  );
};

export default BankActivationBox;
